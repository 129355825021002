import React, { useState } from 'react';
import Panel from '../common/Panel';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import { useUser } from '../../contexts/UserContext';
import { useForm } from '../../hooks/useForm';
import { useCreateLead, useCreatePublicLead } from '../../services/leads';
import AdminSelect from '../common/AdminSelect';

const LeadForm = () => {
  const { user } = useUser();
  const { form, onChange, setValues } = useForm({
    leadEmail: '',
    leadPhone: '',
    leadLastName: '',
    leadSource: 'web',
    leadFirstName: '',
    leadReferredBy: '',
  });
  const [isComplete, setIsComplete] = useState(false);

  const callApi = useCreateLead(
    (res) => {
      if (!res) {
        toast.error('There was an error creating the lead. Please try again.');
        return;
      }
      toast.success('Lead has been successfully created.');
      setIsComplete(true);
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const callPublicApi = useCreatePublicLead(
    () => {
      toast.success('Lead has been successfully created.');
      setIsComplete(true);
    },
    { onError: (err) => toast.error(err.message) }
  );

  const onSubmit = (e) => {
    e.preventDefault();

    const payload = {
      ...form,
      leadAssignedTo: form.leadAssignedTo ? form.leadAssignedTo.value : null,
      leadReferredBy: form.leadReferredBy ? form.leadReferredBy.value : null,
    };

    if (user) {
      callApi.mutate(payload);
    } else {
      callPublicApi.mutate(payload);
    }
  };

  const renderComplete = () => {
    return (
      <Panel className="p-3 text-center">
        <h3>Lead Created</h3>
        <p className="mb-0">
          Your lead for {form.leadFirstName} has been successfully created.
        </p>
        <div className="mt-3">
          <Button variant="primary" onClick={() => window.location.reload()}>
            Start Another
          </Button>
        </div>
      </Panel>
    );
  };

  const renderForm = () => {
    return (
      <form onSubmit={onSubmit}>
        <Panel className="pb-3 mb-3">
          <h3 className="text-center">New Lead</h3>
          <p className="text-center mb-0">
            Complete the form below to create a new lead.
          </p>
        </Panel>
        <Panel className="pb-3 mb-3 space-y-2">
          <Form.Group className="mb-3" controlId="leadFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="leadFirstName"
              value={form.leadFirstName}
              onChange={onChange}
              placeholder="John"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="leadLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="leadLastName"
              value={form.leadLastName}
              onChange={onChange}
              placeholder="Doe"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="leadEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="leadEmail"
              value={form.leadEmail}
              onChange={onChange}
              placeholder="your@email.com"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="leadPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              name="leadPhone"
              value={form.leadPhone}
              onChange={onChange}
              as={InputMask}
              mask="999-999-9999"
              placeholder="111-111-1111"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="leadSource">
            <Form.Label>Source</Form.Label>
            <Form.Select
              name="leadSource"
              value={form.leadSource}
              onChange={onChange}
              aria-label="Default select example"
            >
              <option value={'web'}>Web</option>
              <option value={'email'}>Email</option>
              <option value={'referral'}>Referral</option>
              <option value={'phone'}>Phone</option>
              <option value={'other'}>Other</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="leadReferredBy">
            <Form.Label>Referred By</Form.Label>
            <AdminSelect
              usePublic
              showDefaultOptions
              value={form.leadReferredBy}
              onChange={(val) => setValues({ ...form, leadReferredBy: val })}
              required={true}
            />
          </Form.Group>
          <Form.Group className="d-flex justify-content-end">
            <Button type="submit" variant="primary">
              Create Lead
            </Button>
          </Form.Group>
        </Panel>
      </form>
    );
  };

  return (
    <Container className="pt-4 pb-4" style={{ backgroundColor: 'none' }}>
      <Row>
        <Col xs={12} sm={{ offset: 2, span: 8 }} md={{ span: 6, offset: 3 }}>
          {isComplete ? renderComplete() : renderForm()}
        </Col>
      </Row>
    </Container>
  );
};

export default LeadForm;
